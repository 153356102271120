import { render, staticRenderFns } from "./MaterialVariant.vue?vue&type=template&id=381b9bd8&scoped=true"
import script from "./MaterialVariant.vue?vue&type=script&lang=js"
export * from "./MaterialVariant.vue?vue&type=script&lang=js"
import style0 from "./MaterialVariant.vue?vue&type=style&index=0&id=381b9bd8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "381b9bd8",
  null
  
)

export default component.exports